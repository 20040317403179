<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="questionnaire" service="refMortgage" backlink="/refinance-mortgage/expense" />
      <div class="content">
        <div class="content__container">
          <Question
            service="refMortgage"
            name="obligation"
            question="question_3"
            successUrl="/refinance-mortgage/about-obligations"
            negativeUrl="/refinance-mortgage/problems"
            :droppedSteps="['/refinance-mortgage/about-obligations']"
            :negativeMissedSteps="['/refinance-mortgage/about-obligations']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'
export default {
  name: 'Refinance_mortgage_obligations',
  components: { Backlink, Question }
}
</script>
